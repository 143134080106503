<template>
    <div :class="classes || 'form-group'">
        <label :hidden="label == null || label == ''" ref="input">{{label}}</label>
        <input type="text" v-model="val" class="form-control" :placeholder="placeholder" :disabled="disabled" @keyup="$emit('update:value', $event.target.value)"/>
        <small class="text-danger">{{Array.isArray(error) ? error[0] : error}}</small>
    </div>
</template>

<script>
export default {
    props: [
        'label', 'error', 'classes', 'value', 'placeholder', 'disabled'
    ],
    data() {
        return {
            val: this.value
        }
    },
    methods: {
        reset: function() {
            this.val = ""
        }
    }
}
</script>
